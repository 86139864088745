import React, { useContext, useMemo, useRef } from "react"

import { DMThemeGenerator } from "@supernovaio/dm"
import { cn } from "@supernovaio/dm/src/utils/cn"
import { hexToOklch } from "@supernovaio/dm/src/utils/color"
import { getColorMode } from "@supernovaio/dm/src/utils/getColorMode"

import { ResolvedStyle } from "../../DMTokenPreview.types"
import { ThemeIcon } from "../tiles/ThemeIcon"
import { contrastColor } from "../token-utils"

export type ColorSwatchProps = Pick<ResolvedStyle, "background" | "opacity"> & {
  themes?: string[]
}

export function ColorSwatch(props: ColorSwatchProps) {
  const { background, opacity, themes } = props

  const constructedCSS = useMemo(
    () => ({
      background: background || undefined,
      opacity: opacity || undefined,
    }),
    [background, opacity]
  )

  const { backgroundColor = "#FFFFFF", isEditorWhite } = useContext(DMThemeGenerator) || {}

  const lContent = hexToOklch(backgroundColor).l

  const { l, alpha } = background
    ? hexToOklch(background)
    : { l: undefined, alpha: undefined }

  // Get the color mode of the swatch – it gets the data-rs-color-mode attribute from the parent element
  // For now if editorIsWhite is true there is hardcoded "#FFFFFF" for the editor color, but in the future it might get custom color
  // TODO: This needs revision – not sure if this is the best way to get the color mode of the parent element

  const ref = useRef<HTMLDivElement>(null)
  const colorMode = getColorMode(ref.current)
  const backgroundLuminance =
    colorMode === "editor" && isEditorWhite ? 1 : lContent

  // Calculate luminance difference between swatch color and background

  const lightnessCheck =
    l !== undefined ? Math.abs(backgroundLuminance - l) : undefined
  // Fetch the swatch opacity (set 1 if undefined)

  const opacityCheck = alpha ?? 1

  // Apply border classes if the contrast is too low or opacity is not 1

  const appliedBorderClasses =
    lightnessCheck !== undefined &&
    (lightnessCheck < 0.15 || opacityCheck !== 1)
      ? "after:content-[''] after:absolute after:inset-0 after:border after:border-neutral-faded after:rounded"
      : ""

  return (
    <div
      ref={ref}
      className={cn(
        "preview-tile",
        "bg-checkered h-full w-full overflow-hidden rounded",
        appliedBorderClasses
      )}
    >
      <div
        className={cn("h-full w-full relative", { "bg-black": opacity })}
        style={constructedCSS}
      >
        {themes && (
          <ThemeIcon
            themes={themes}
            contrastingColor={contrastColor(background)}
          />
        )}
      </div>
    </div>
  )
}
