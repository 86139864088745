import React from "react"

import { cn } from "@supernovaio/dm/src/utils/cn"

import {
  IconTokenBlur,
  IconTokenDimension,
  IconTokenDuration,
  IconTokenLetterSpacing,
  IconTokenLineHeight,
  IconTokenParagraphSpacing,
  IconTokenRadius,
  IconTokenString,
  IconTokenZindex,
} from "@supernovaio/icons"

import { ResolvedStyle, TokenPreview, TokenType } from "../DMTokenPreview.types"
import { ReferenceBadgeIcon } from "../assets/ReferenceBadgeIcon"
import {
  BorderSwatch,
  ColorSwatch,
  IconSwatch,
  OptionsSwatch,
  ShadowSwatch,
  TextSwatch,
} from "../utils/swatches"
import {
  isShadowTokenMetadata,
  isTypographyTokenMetadata,
} from "../utils/token-utils"

export type DMTokenPreviewSwatchProps = {
  token: TokenPreview | null
  resolvedStyle?: ResolvedStyle
}

export function DMTokenPreviewSwatch(props: DMTokenPreviewSwatchProps) {
  const { token, resolvedStyle } = props

  // If there is no info about token, we render empty swatch
  if (!token) {
    return (
      <div className="h-icon-small w-icon-small border-neutral rounded border border-dashed" />
    )
  }

  const tokenTypeSwatch: Partial<{ [key in TokenType]: React.ReactNode }> = {
    blur: <IconSwatch icon={<IconTokenBlur />} />,
    borderWidth: <BorderSwatch {...resolvedStyle} />,
    border: <BorderSwatch {...resolvedStyle} />,
    color: <ColorSwatch background={resolvedStyle?.background} />,
    copy: <IconSwatch icon={<IconTokenString />} />,
    dimension: <IconSwatch icon={<IconTokenDimension />} />,
    duration: <IconSwatch icon={<IconTokenDuration />} />,
    fontFamily: <TextSwatch {...resolvedStyle} />,
    fontSize: (
      <TextSwatch
        {...resolvedStyle}
        fontSizeValue={
          isTypographyTokenMetadata(token.meta) ? token.meta?.fontSize : null
        }
      />
    ),
    fontWeight: <TextSwatch {...resolvedStyle} />,
    gradient: <ColorSwatch {...resolvedStyle} />,
    lineHeight: <IconSwatch icon={<IconTokenLineHeight />} />,
    letterSpacing: <IconSwatch icon={<IconTokenLetterSpacing />} />,
    opacity: <ColorSwatch {...resolvedStyle} />,
    paragraphSpacing: <IconSwatch icon={<IconTokenParagraphSpacing />} />,
    radius: <IconSwatch icon={<IconTokenRadius />} />,
    shadow: isShadowTokenMetadata(token.meta) && (
      <ShadowSwatch
        type={token.meta?.type}
        x={token.meta?.x}
        y={token.meta?.y}
      />
    ),
    size: <IconSwatch icon={<IconTokenDimension />} />,
    space: <IconSwatch icon={<IconTokenDimension />} />,
    string: <IconSwatch icon={<IconTokenString />} />,
    textCase: <OptionsSwatch value={token.displayValue} />,
    textDecoration: <OptionsSwatch value={token.displayValue} />,
    visibility: <OptionsSwatch value={token.displayValue} />,
    typography: (
      <TextSwatch
        {...resolvedStyle}
        fontSizeValue={
          isTypographyTokenMetadata(token.meta) ? token.meta?.fontSize : null
        }
      />
    ),
    zIndex: <IconSwatch icon={<IconTokenZindex />} />,
  }

  return (
    <div className="h-icon-small w-icon-small relative shrink-0 ml-4">
      {(token.referencedTo || token.referenceResolutionFailed) && (
        <ReferenceBadgeIcon className="z-2 absolute -left-4 -top-4 text-neutral" />
      )}
      <div
        className={cn("h-full w-full overflow-hidden rounded", {
          "clip-path-token-swatch":
            token.referencedTo || token.referenceResolutionFailed,
        })}
      >
        {tokenTypeSwatch[token.tokenType]}
      </div>
    </div>
  )
}
