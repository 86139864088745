"use client"

import React from "react"

import Actionable from "@supernovaio/dm/src/components/Actionable/Actionable"
import { cn } from "@supernovaio/dm/src/utils/cn"
import { normalizeTestId } from "@supernovaio/dm/src/utils/normalizeTestId"

import { Slot } from "@radix-ui/react-slot"

type IllustrationElement = React.ReactElement<{
  className?: string
  focusable?: boolean
}>

export type DMIllustratedMessageProps = {
  size?: "small" | "medium" | "large" | "full"
  title?: string
  description: string
  descriptionSlot?: React.ReactNode
  /* If provided, a link button is auto-created and opens the url on click */
  linkUrl?: string
  linkTitle?: string
  /* If a node with multiple children are provided, they are spaced according to the designs */
  actionsSlot?: React.ReactNode
  illustration: IllustrationElement | React.ComponentType
  dataTestId?: string
  containerClassName?: string
  className?: string
}

/** Workaround for now, will be removed when we will migrate all illustrations */
function renderIllustration(
  illustration: DMIllustratedMessageProps["illustration"]
): IllustrationElement {
  let illustrationElement

  if (React.isValidElement(illustration)) {
    illustrationElement = illustration
  } else {
    const Illustration = illustration

    illustrationElement = <Illustration />
  }

  return illustrationElement
}

const DMIllustratedMessage = React.forwardRef<
  HTMLDivElement,
  DMIllustratedMessageProps
>(
  (
    {
      title,
      description,
      descriptionSlot,
      linkUrl,
      linkTitle = "Learn more",
      size = "medium",
      illustration,
      actionsSlot,
      dataTestId,
      containerClassName,
      className,
    },
    ref
  ) => {
    const Illustration = renderIllustration(illustration)

    return (
      <div
        ref={ref}
        className={cn(
          "flex flex-col items-center",
          {
            "max-w-[272px]": size === "small",
            "max-w-[420px]": size === "medium",
            "max-w-[640px]": size === "large",
            "max-w-full": size === "full",
            "gap-12": Boolean(title),
            "gap-36": size === "full",
          },
          containerClassName
        )}
        data-test-id={normalizeTestId(dataTestId)}
      >
        <span aria-hidden="true">
          {React.cloneElement(Illustration, {
            focusable: false,
            className: `h-full w-auto min-w-full ${
              Illustration.props.className ?? ""
            }`,
          })}
        </span>
        <div
          className={cn(
            "align-ce flex w-full flex-col items-center gap-24 text-center",
            {
              "max-w-[272px]": size === "small",
              "max-w-[420px]": size === "medium",
              "max-w-[640px]": size === "large" || size === "full",
            },
            className
          )}
        >
          <div
            className={cn(
              "flex w-full flex-col items-center",
              size === "large" || size === "full" ? "gap-8" : "gap-4"
            )}
          >
            <div
              className={cn("text-neutral font-bold", {
                "text-body-small": size === "small",
                "text-title-3": size === "medium",
                "text-title-2": size === "large" || size === "full",
              })}
            >
              {title}
            </div>
            <div
              className={cn("text-neutral-faded", {
                "text-body-small": size === "small",
                "text-body": size === "medium",
                "text-body-large": size === "large" || size === "full",
              })}
            >
              {description}
            </div>
          </div>
          {descriptionSlot && <div className="w-full">{descriptionSlot}</div>}
          {(actionsSlot || linkUrl) && (
            <div className="flex flex-col items-center gap-24 w-full">
              {actionsSlot && (
                <Slot className="flex flex-row gap-8">{actionsSlot}</Slot>
              )}
              {linkUrl && (
                <Actionable
                  isExternalLink
                  className="text-body text-primary font-semibold focus-visible:ring-2"
                  href={linkUrl}
                  onClick={() => {}}
                  data-test-id="link"
                >
                  {linkTitle}
                </Actionable>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }
)

DMIllustratedMessage.displayName = "DMIllustratedMessage"

export { DMIllustratedMessage }
