import { ThemeType } from "../types/theme"

export function getColorMode(element: HTMLElement | null): ThemeType | null {
  if (!element) {
    return null
  }

  const colorMode = element
    .closest("[data-rs-color-mode]")
    ?.getAttribute("data-rs-color-mode") as ThemeType | undefined
  return colorMode ?? null
}
